import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  td a {
    font-weight: bold;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  th.home-offers-list-th-status {
    width: 10%;
  }

  th.home-offers-list-th-name {
  }

  th.home-offers-list-th-created-at {
    width: 15%;
  }

  th.home-offers-list-th-offer {
  }

  th.home-offers-list-th-actions {
    width: 10%;
  }
`;

export const HelpTable = styled.table`
  border-top: 1px solid #e0e0e0;
  margin-top: 3rem;

  tr td {
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem;
  }
`;

export const NoContent = styled.p`
  text-align: center;
  font-size: 2rem;
  padding: 4rem 0;
  background: #eee;
`;
