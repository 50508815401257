import {
  ControlledTable,
  Icon,
  IconButton,
  Modal,
  Tooltip
} from '@asgard/ui-library';
import { HeaderWithActions } from '@components/layout/HeaderWithActions';
import { OfferStatus } from '@components/OfferStatus';
import { HtmlTrans } from '@components/shared/HtmlTrans';
import { TimeAgo } from '@components/TimeAgo';
import { UserGuiding } from '@components/UserGuiding';
import {
  faCircleQuestion,
  faEmptySet,
  faFilePen,
  faNewspaper
} from '@fortawesome/pro-solid-svg-icons';
import { useCompany } from '@hooks/api/useCompany';
import { useI18n } from '@hooks/useI18n';
import { getOfferPagePath } from '@utils/app-pages';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as S from './HomeOffers.styled';
import { HomeOffersProps } from './HomeOffers.types';

export const HomeOffers: React.FC<HomeOffersProps> = ({ offers }) => {
  const { t } = useI18n('home');
  const { locale } = useRouter();
  const { company } = useCompany();

  const [isOfferStatusHelpModalOpened, setIsOfferStatusHelpModalOpened] =
    useState(false);
  const [
    isOffersListActionsHelpModalOpened,
    setIsOffersListActionsHelpModalOpened
  ] = useState(false);

  return (
    <S.Root>
      <UserGuiding guide="offer_list_page" />

      <HeaderWithActions
        title={`${t('home.offers.list.title', { company: company?.name })}`}
      />

      {offers.length <= 0 ? (
        <S.NoContent>
          <Icon icon={faEmptySet} size="4xl" />
          <br />
          {t('home.offers.list.no-content')}
        </S.NoContent>
      ) : (
        <>
          <ControlledTable<HomeOffersProps['offers'][0]>
            dataRowId="id"
            data={offers}
            columns={[
              {
                type: 'badge',
                label: t('home.offers.list.header.status'),
                labelSlot: (
                  <Tooltip content={t('common:common.help')}>
                    <IconButton
                      icon={faCircleQuestion}
                      size="2xs"
                      aria-label={t('common:common.help')}
                      variant="bare"
                      title={t('common:common.help')}
                      onClick={(e) => {
                        e.stopPropagation();

                        setIsOfferStatusHelpModalOpened(true);
                      }}
                    />
                  </Tooltip>
                ),
                className: 'home-offers-list-th-status',
                sortable: true,
                sortValue: ({ status }) => status,
                render: ({ status }) => <OfferStatus status={status} />
              },

              {
                type: 'text',
                label: t('home.offers.list.header.name'),
                className: 'home-offers-list-th-name',
                sortable: true,
                sortValue: ({ name }) => name,
                render: ({ id, name, status }) => (
                  <>
                    {status !== 'CANCELLED' ? (
                      <Link href={getOfferPagePath(id, locale)} passHref>
                        <a>{name || t('common:common.no-name-offer')}</a>
                      </Link>
                    ) : (
                      <Tooltip content={t('common:common.process-cancelled')}>
                        <strong>
                          {name || t('common:common.no-name-offer')}
                        </strong>
                      </Tooltip>
                    )}
                  </>
                )
              },

              {
                type: 'text',
                label: t('home.offers.list.header.createdAt'),
                className: 'home-offers-list-th-created-at',
                sortable: true,
                sortValue: ({ creationDateString }) =>
                  new Date(creationDateString).getTime(),
                render: ({ creationDateString }) => (
                  <TimeAgo dateIsoString={creationDateString} />
                )
              }
            ]}
          />
        </>
      )}

      <Modal
        isOpen={isOfferStatusHelpModalOpened}
        onClose={() => setIsOfferStatusHelpModalOpened(false)}
        title={t('home.offers.list.modal-offer-status-help.title')}
      >
        <HtmlTrans
          ns="home"
          i18nKey={'home.offers.list.modal-offer-status-help.desc'}
        />

        <S.HelpTable>
          <tr>
            <td>
              <OfferStatus status="PENDING" />
            </td>
            <td>{t('common:common.offer-status-desc.PENDING')}</td>
          </tr>

          <tr>
            <td>
              <OfferStatus status="SEARCHING_CANDIDATES" />
            </td>
            <td>{t('common:common.offer-status-desc.SEARCHING_CANDIDATES')}</td>
          </tr>

          <tr>
            <td>
              <OfferStatus status="ACTIVE" />
            </td>
            <td>{t('common:common.offer-status-desc.ACTIVE')}</td>
          </tr>

          <tr>
            <td>
              <OfferStatus status="PAUSED" />
            </td>
            <td>{t('common:common.offer-status-desc.PAUSED')}</td>
          </tr>
          <tr>
            <td>
              <OfferStatus status="FINISHED" />
            </td>
            <td>{t('common:common.offer-status-desc.FINISHED')}</td>
          </tr>
          <tr>
            <td>
              <OfferStatus status="CANCELLED" />
            </td>
            <td>{t('common:common.offer-status-desc.CANCELLED')}</td>
          </tr>
        </S.HelpTable>
      </Modal>

      <Modal
        isOpen={isOffersListActionsHelpModalOpened}
        onClose={() => setIsOffersListActionsHelpModalOpened(false)}
        title={t('home.offers.list.modal-actions-help.title')}
      >
        <HtmlTrans
          ns="home"
          i18nKey={'home.offers.list.modal-actions-help.desc'}
        />

        <S.HelpTable>
          <tr>
            <td>
              <IconButton
                size="xs"
                icon={faFilePen}
                schema="neutral"
                variant="ghost"
                aria-label={t('home.offers.list.actions.view-draft-offer')}
                title={t('home.offers.list.actions.view-draft-offer')}
              />
            </td>
            <td>{t('home.offers.list.modal-actions-help.view-draft')}</td>
          </tr>

          <tr>
            <td>
              <IconButton
                size="xs"
                icon={faNewspaper}
                schema="neutral"
                variant="ghost"
                aria-label={t('home.offers.list.actions.view-published-offer')}
                title={t('home.offers.list.actions.view-published-offer')}
              />
            </td>
            <td>
              {t('home.offers.list.modal-actions-help.view-published-offer')}
            </td>
          </tr>
        </S.HelpTable>
      </Modal>
    </S.Root>
  );
};
