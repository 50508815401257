import styled from 'styled-components';

export const LoginContainer = styled.div`
  text-align: center;
  margin-top: 3rem;

  div > div > div {
    padding: 5rem 0;
  }
`;
