import { getAccessToken } from '@auth0/nextjs-auth0';
import { HomePage, HomePageProps } from '@features/home';
import { ServiceOffer } from '@services/offer';
import { getTranslations } from '@utils/i18n';
import { createGetServerSideProps, getLoggedUser } from '@utils/ssr';

export default HomePage;

export const getServerSideProps = createGetServerSideProps<HomePageProps>(
  async function ({ locale, req, res }) {
    let offers;
    const [translations, user] = await Promise.all([
      getTranslations(['common', 'home'], locale),
      getLoggedUser(req, res)
    ]);

    if (user) {
      const { accessToken } = await getAccessToken(req, res);
      offers = await ServiceOffer.getAll({
        useAppProxy: false,
        token: accessToken
      });
    }

    return {
      props: {
        ...translations,
        user,
        offers: offers ? JSON.parse(JSON.stringify(offers)) : null
      }
    };
  }
);
