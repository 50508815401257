import { Page } from '@components/layout/Page';
import { HomePageProps } from './HomePage.types';
import { HomeLogin } from './login';
import { HomeOffers } from './offers';

export const HomePage: React.FC<HomePageProps> = ({ user, offers }) => {
  return (
    <Page user={user} pageId="offers">
      {user ? <HomeOffers offers={offers || []} /> : <HomeLogin />}
    </Page>
  );
};
