import { ButtonLink, Card, Header } from '@asgard/ui-library';
import { faUserAstronaut } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from '@hooks/useI18n';
import { APP_PAGES, getPagePath } from '@utils/app-pages';
import { useRouter } from 'next/router';
import * as S from './HomeLogin.styled';

export const HomeLogin: React.FC = () => {
  const { t } = useI18n('common');
  const { locale } = useRouter();

  return (
    <S.LoginContainer>
      <Card hideImage>
        <Header as="h2" size="3xl">
          {t('home:home.welcome_title')}
        </Header>

        <p>{t('home:home.welcome_text')}</p>

        <ButtonLink
          label={t('common.login')}
          schema="brand1"
          variant="hard"
          startIcon={faUserAstronaut}
          href={getPagePath(APP_PAGES.login, locale)}
        />
      </Card>
    </S.LoginContainer>
  );
};
